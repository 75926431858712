import { Button, Flex, H1, H2, Wrapper } from '@renderbus/common/components'
import { color, hideSMDown, mbSize, Media, spacing, typography } from '@renderbus/common/theme'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Discount from '../images/effect-price/discount.svg'
import TopPrice from '../images/effect-price/top-price.svg'
import SloganBg from '../images/effect-price/slogan-bg.png'

export const ContentContainer = styled.div`
  text-align: center;
  background: ${p => (p.theme === 'black' ? color.background : color.panel)};
  @media (max-width: 600px) {
    padding: ${spacing.base} 0 40px;
  }
`
export const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 1022px;
  .effect-price-billboard {
    height: 100%;
    z-index: 0;
  }
  ${Media.lessThan(Media.small)} {
    .effect-price-billboard {
      height: 568px;
    }
  }
`
export const ContentTitleWrapper = styled.div`
  position: absolute;
  top: 185px;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
  ${Media.lessThan(Media.small)} {
    top: 125px;
  }
`
export const Slogan1 = styled.div`
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 22px;
  span.slogan-has-image {
    width: 186px;
    line-height: 34px;
    margin-left: 12px;
    display: inline-block;
    background: url(${SloganBg});
    background-size: 100%;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    margin-bottom: 16px;
    span.slogan-has-image {
      width: 114px;
      line-height: 20px;
      margin-left: 9px;
    }
  }
`
export const Slogan2 = styled.h2`
  font-size: 24px;
  font-weight: unset;
  background: linear-gradient(-90deg, rgba(63, 188, 43, 0.4) 0%, rgba(64, 197, 112, 0.4) 100%);
  width: 280px;
  line-height: 42px;
  border-radius: 21px;
  color: #fff;
  font-weight: regular;
  ${Media.lessThan(Media.small)} {
    width: 160px;
    line-height: 25px;
    border-radius: 12px;
    font-size: 14px;
  }
`
export const SloganWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  ${Media.lessThan(Media.small)} {
    margin-top: 0px;
  }
`
export const ContentTitleH1 = styled(H1)`
  font-size: 60px;
  position: relative;
  margin: 0 auto;
  line-height: 60px;
  font-weight: 400;
  span {
    font-weight: bold;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 32px;
    line-height: 32px;
  }
`
export const ContentTitle = styled(H2)`
  margin-bottom: 1rem;
  margin-top: 50px;
  font-size: ${typography.h1};
`
export const ContentSubTitle = styled.p`
  font-size: ${typography.text};
  font-weight: bold;
  color: ${color.prompt};
  margin-bottom: ${p => (p.marginBottom ? p.marginBottom : '50px')};
  ${Media.lessThan(Media.small)} {
    margin-bottom: ${spacing.large};
  }
`
export const RenderPriceContainer = styled(Flex)`
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1280px;
  margin-bottom: 100px;
  @media (max-width: 599px) {
    justify-content: center;
  }
`
export const RenderCardContainer = styled(Flex)`
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1280px;
  margin-bottom: 60px;
  position: relative;
  @media (max-width: 599px) {
    justify-content: center;
  }
`
export const RenderCard = styled.div`
  position: relative;
  padding: ${spacing.large} 0 70px;
  width: 400px;
  height: ${p => (p.isVip ? '620px' : '480px')};
  color: white;
  background: linear-gradient(45deg, rgba(100, 110, 100, 0.3), rgba(32, 32, 32, 0.3));
  border-radius: 12px;
  // border: 1px solid;
  border-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.8)) 1 1;
  &:hover {
    background: linear-gradient(
      45deg,
      rgba(64, 197, 112, 0.3),
      rgba(80, 90, 80, 0.3),
      rgba(32, 32, 32, 0.3)
    );
  }
  ${Media.lessThan(Media.small)} {
    width: 340px;
    height: ${p => (p.isVip ? '495px' : '323px')};
    margin-bottom: 20px;
  }
`
export const CardTopPrice = styled.div`
  position: absolute;
  width: 216px;
  height: 50px;
  font-size: ${typography.text};
  background: url(${TopPrice});
  left: 0;
  right: 0;
  margin: auto;
  top: -10px;
  padding-top: 10px;
  ${Media.lessThan(Media.small)} {
  }
`
export const CardTitle = styled.div`
  font-size: 36px;
  font-weight: 500;
  margin: 30px 0 20px;
  &.not-vip {
    margin: 40px 0 75px;
  }
  &.more-requirement {
    margin: 40px 0 70px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 27px;
    margin: 30px 0 12px;
    &.not-vip {
      margin: 20px 0 32px;
    }
    &.more-requirement {
      margin: 20px 0 48px;
    }
  }
`
export const CardSubTitle = styled.div`
  display: inline-block;
  position: relative;
  margin: 10px 0 40px;
  ${Media.lessThan(Media.small)} {
  }
`
export const CustomizedIconBox = styled.div`
  margin: 0.67em 0;
  ${Media.lessThan(Media.small)} {
  }
`
export const CardPriceTag = styled.span`
  font-size: 24px;
  font-family: Arial;
  font-weight: normal;
  vertical-align: top;
  color: #13cb75;
  ${Media.lessThan(Media.small)} {
    font-size: 18px;
  }
`
export const CardPrice = styled.span`
  font-size: ${typography.h1};
  font-weight: 900;
  line-height: 40px;
  color: #13cb75;
  font-family: Arial;
  ${Media.lessThan(Media.small)} {
  }
`
export const CardPreviousPrice = styled.p`
  text-decoration-line: line-through;
  ${Media.lessThan(Media.small)} {
  }
`
export const CardContent = styled.div`
  line-height: 32px;
  p {
    font-size: 24px;
    margin: 0;
  }
  @media (min-width: 600px) {
    height: 85px;
  }
  @media (max-width: 600px) {
    padding-bottom: ${spacing.base};
    line-height: 28px;
    p {
      font-size: 18px;
    }
  }
`
export const CardRibbon = styled.div`
  position: absolute;
  width: 48px;
  height: 26px;
  background: url(${Discount});
  top: -8px;
  right: 0px;
  ${Media.lessThan(Media.small)} {
  }
`
export const DescriptionText = styled.div`
  max-width: 1280px;
  color: ${color.prompt};
  font-size: ${typography.textSmall};
  text-align: left;
  margin: ${spacing.large} auto;
  p {
    margin: 5px 0;
  }
  &.description {
    margin: ${spacing.large} auto 80px;
  }
  .description-content {
    display: flex;
    gap: 44px;
  }
  ${Media.lessThan(Media.small)} {
    max-width: 340px;
    &.description {
      margin-bottom: 0;
    }
    .description-content {
      display: block;
    }
  }
`
export const MoreButtonContainer = styled(Flex)``

export const BannerButton = styled(Button)`
  font-size: ${typography.h3};
  font-weight: normal;
  ${Media.lessThan(Media.small)} {
  }
`
export const DoubleElTitle = styled.img`
  display: block;
  margin: auto;
  padding-top: 0;
  max-width: 100%;
  ${Media.lessThan(Media.small)} {
    padding-top: 150px;
  }
  ${Media.lessThan(Media.small)} {
  }
`
export const DoubleElTime = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;
`
export const Highlight = styled.span`
  color: ${color.vermilion};
  font-weight: 400;
`
export const RowBannerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100px;
  ${hideSMDown}
`

export const RowBannerButton = styled.button`
  position: absolute;
  top: 50%;
  right: 400px;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 140px;
  height: 50px;
  border: none;
  background: linear-gradient(to left, #ffae66, #f7c894);
  color: #105637;
  border-radius: 25px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  @media (max-width: 1700px) {
    right: 200px;
  }
`

export const RowBannerTip = styled.span`
  position: absolute;
  top: 50%;
  right: 420px;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 17px;
  cursor: pointer;
`
export const RowBannerDetailLink = styled(Link)`
  position: absolute;
  top: 50%;
  right: 450px;
  transform: translateY(-50%);
  color: white;
  font-size: ${typography.text};
  cursor: pointer;
  z-index: 2;
  @media (max-width: 1700px) {
    right: 100px;
  }
`
export const PriceWrapper = styled(Wrapper)`
  margin-top: -470px;
  ${Media.lessThan(Media.small)} {
    margin-top: -670px;
  }
`
export const VipActivityContent = styled.div`
  & > div {
    width: 244px;
    margin: 0 auto;
    text-align: left;
  }
  span {
    font-size: 18px;
    line-height: 30px;
    margin-left: 10px;
    vertical-align: middle;
  }
  ${Media.lessThan(Media.small)} {
    & > div {
      width: 194px;
    }
    span {
      font-size: 14px;
      line-height: 22px;
      margin-left: 7px;
    }
  }
`
export const Separator = styled.fieldset`
  font-size: 20px;
  color: #999;
  border: none;
  border-top: 1px solid #999;
  width: 244px;
  margin: 0 auto;
  padding-bottom: 0;
  .inner {
    margin: 0 auto;
    padding: 0 14px;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 16px;
    width: 194px;
  }
`
export const CardButton = styled.a`
  position: absolute;
  bottom: 70px;
  left: 50%;
  display: block;
  margin: 0 auto;
  width: 180px;
  border: 2px solid #13cb75;
  border-radius: 23px;
  color: #13cb75;
  font-size: 20px;
  line-height: 46px;
  margin-top: 50px;
  margin-left: -90px;
  transition: all 0.3s;
  &:hover {
    background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
    box-shadow: 0px 6px 11px 1px rgba(64, 194, 93, 0.3);
    border-radius: 23px;
    color: #fff;
  }
  ${Media.lessThan(Media.small)} {
    bottom: 35px;
    width: 133px;
    line-height: 34px;
    margin-left: -66px;
    font-size: 16px;
    color: #fff;
    background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
  }
`
export const TipWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  margin-top: 40px;
  ${Media.lessThan(Media.small)} {
    width: 340px;
    margin: 0 auto;
    margin-top: 24px;
    text-align: left;
    line-height: 24px;
  }
`
export const MoreButton = styled.a`
  display: block;
  width: 160px;
  line-height: 40px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    color: #13cb75;
    background: #ffffff;
  }
  ${Media.lessThan(Media.small)} {
    width: 130px;
    line-height: 30px;
    border-radius: 15px;
    font-size: 12px;
  }
`

export const CardFreeSample = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  margin-top: -15px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(28)};
  }
`
