import React from 'react'
import { baseLink } from '@renderbus/common/service'

import {
  CarrotCouponContainer,
  CarrotInfo,
  CarrotPrice,
  CarrotPriceSymbol,
  CouponButton,
  CouponValidityPeriod,
  LeftWrapper,
  RightWrapper,
} from './atom'

class CarrotCoupon extends React.PureComponent {
  render() {
    const { recharge, free, buttonId } = this.props
    const link = `${baseLink()}/center/user/topUp?chooseCard=${recharge}`
    return (
      <CarrotCouponContainer>
        <LeftWrapper>
          <CarrotPrice>
            <CarrotPriceSymbol>
              <span className='symbol'>¥</span>
              <span>到账</span>
            </CarrotPriceSymbol>
            {recharge + free}
          </CarrotPrice>
          <CarrotInfo>
            首充{recharge}送{free}元渲染券
          </CarrotInfo>
        </LeftWrapper>

        <RightWrapper>
          <CouponButton id={buttonId} href={link} target='_blank' rel='nofollow'>
            立即领取
          </CouponButton>
          <CouponValidityPeriod>有效期1个月</CouponValidityPeriod>
        </RightWrapper>
      </CarrotCouponContainer>
    )
  }
}

export default CarrotCoupon
