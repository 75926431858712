import { color, Media, spacing, typography } from '@renderbus/common/theme'
import styled, { css } from 'styled-components'
import Coupon from '../../../images/effect-price/coupon.png'
import CouponHover from '../../../images/effect-price/coupon-hover.png'
const Ball = css`
  content: '';
  position: absolute;
  top: 80px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${color.panel};
`

export const CarrotCouponContainer = styled.div`
  width: 400px;
  height: 150px;
  margin: 0;
  padding: 38px 25px 38px 46px;
  background-image: url(${Coupon});
  background-size: 100% 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  &:hover {
    width: 426px;
    height: 170px;
    padding: 38px 38px 38px 59px;
    background-image: url(${CouponHover});
    margin: 0 -13px -20px -13px;
  }
  ${Media.lessThan(Media.small)} {
    margin: ${spacing.small};
    width: 340px;
    height: 127px;
    padding: 27px 25px 27px 32px;
  }
`

export const CarrotPrice = styled.div`
  font-size: 36px;
  margin: 0;
  padding: 0;
  line-height: 20px;
  font-size: 52px;
  font-family: Arial;
  font-weight: bold;
  color: #13cb75;
  text-align: left;
  ${Media.lessThan(Media.small)} {
    font-size: 38px;
  }
`
export const CarrotPriceSymbol = styled.span`
  color: #13cb75;
  font-size: ${typography.text};
  display: inline-block;
  margin-right: 7px;
  span {
    display: block;
    width: 32px;
    line-height: 16px;
    text-align: right;
    &.symbol {
      font-family: Arial;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
  ${Media.lessThan(Media.small)} {
    span {
      min-width: 28px;
      &.symbol {
        margin-bottom: 0;
      }
    }
  }
`

export const CarrotInfo = styled.p`
  margin: 0;
  padding: 0;
  height: 24px;
  line-height: 24px;
  font-size: ${typography.textSmall};
  color: #dddddd;
  font-size: 16px;
  margin-top: 20px;
  ${Media.lessThan(Media.small)} {
    margin-top: 15px;
  }
`
export const LeftWrapper = styled.div``
export const RightWrapper = styled.div``
export const CouponButton = styled.a`
  display: block;
  width: 121px;
  line-height: 35px;
  border: 1px solid #13cb75;
  border-radius: 17px;
  font-size: 16px;
  color: #13cb75;
  cursor: pointer;
  &:hover {
    background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
    color: #ffffff;
    box-shadow: 0px 4px 7px 1px rgba(64, 194, 93, 0.3);
  }
  ${Media.lessThan(Media.small)} {
    width: 89px;
    line-height: 25px;
    border-radius: 12px;
    font-size: 12px;
    margin-top: 8px;
    background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
    color: #ffffff;
  }
`
export const CouponValidityPeriod = styled.div`
  margin-top: 23px;
  font-size: 14px;
  color: #999999;
  line-height: 24px;
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    margin-top: 13px;
  }
`
